<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
      {{ message }} 
  </div>
  <div class="mt-1 mb-2 text-center font-weight-bold">
      {{ checkWord }} 
  </div>
  <div class="my-1">
    <input type="text" class="form-control" [(ngModel)]="confirmationWord">
  </div>
  <div class="text-danger">
      {{ errorMsg }}
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>