import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor() { }


  //SLC-USERS DEV-API : http://34.89.29.55:3000/

  //SLC-USERS PRO-API : https://users.salicru.com/

  getToken(): string {
    return localStorage.getItem('uap-token');
  }

  putToken(token: string) {
    return localStorage.setItem('uap-token', token);
  }

  setApiUrl(apiUrl: string) {
    return localStorage.setItem('uap-apiUrl', apiUrl);
  }

  getApiUrl() {
    return localStorage.getItem('uap-apiUrl');
  }

  clearApiUrl() {
     localStorage.removeItem('uap-apiUrl');
  }

  clearToken() {
    localStorage.removeItem('uap-token');
  }

  isLogged(): boolean {
    const token = this.getToken();
    return token !== null && token !== '';
  }
}
