<div class="mainContainer">

  <div class="row">
    <div class="col-11">
        <h2>User roles</h2>
    </div>
    <div class="col-1">
        <button type="button" (click)="createRole()" class="btn btn-primary" style="float: right;">Add new role</button>
    </div>
  </div>

  <div class="mt-4" *ngIf="roles.length == 0">
    There are no user roles to show.
  </div>

  <table class="table mt-4" *ngIf="roles.length > 0">
    <thead>
      <tr>
        <th>Name</th>
        <th>Platform</th>
        <th>Type</th>
        <th>Default</th>
        <th class="action-buttons">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles">
        <td>{{role.name}}</td>
        <td>{{role.platform}}</td>
        <td>{{role.type}}</td>
        <td><i *ngIf="role.default" class="fa fa-check" style="color:green"></i></td>
        <td>
          <button class="btn btn-secondary m-1" (click)="editRole(role)" placement="bottom" ngbTooltip="Edit role">
            <i class="fa fa-fw fa-edit"></i>
          </button>
          <button class="btn btn-danger m-1" [disabled]="role.initial || role.default" (click)="deleteRole(role)" placement="bottom" ngbTooltip="Delete role">
            <i class="fa fa-fw fa-trash"></i>
          </button> 
        </td>
      </tr>
    </tbody>
  </table>

</div>