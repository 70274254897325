import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ApiService, AuthService } from 'src/app/services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { getPrefixCountryCode } from 'src/app/utils/utils';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/models/role.model';
import { Distributor } from 'src/app/models/distributor.model';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  user: User;
  emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  nameRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
  prefixCountryCode = getPrefixCountryCode();
  userType: string;
  userTypes: Array<any> = [
    { 'value': 'pro', 'display': 'Professional' },
    { 'value': 'per', 'display': 'Personal' }
  ];
  platforms: string[] = [];
  roles: Role[] = [];
  distributors: Distributor[] = [];
  selectedDistributorId: string = "";
  platformRoles: any = {};
  platformSelectedRoles: any = {};
  keyword: string = '';

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.apiService.getPlatforms().subscribe(res => {
      if (res.status) {
        this.platforms = res.data;

        this.apiService.getRoles().subscribe(res2 => {
          if (res2.status) {
            this.roles = res2.data;

            this.route.queryParams.subscribe(params => {
              let userId = params['id'];
              this.loadUser(userId);
            });
          }
        });
      }
    });
  }

  loadUser(id: string){
    this.apiService.getUser(id).subscribe((res) => {
      if (res['status']) {
        this.user = res.data;
        this.userType = this.user.professional ? 'pro' : 'per';
        const distributorId = this.user.distributorId;
        if (distributorId) {
          this.selectedDistributorId = distributorId;
        } else {
          this.selectedDistributorId = "";
        }
        if (this.selectedDistributorId) {
          this.apiService.getDistributors(this.selectedDistributorId).subscribe(res => {
            if (res.status && res.data.length > 0) {
              const distributor: Distributor = res.data[0];
              this.keyword = distributor.id + " - " + distributor.description; 
            }
          });
        }

        this.setPlatformRoles();
      }
    });
  }

  setPlatformRoles() {
    for (const platform of this.platforms)
      this.platformRoles[platform] = this.roles.filter(r => r.platform == platform);

    if (Array.isArray(this.user.roles)){
      for (const userRoleId of this.user.roles){
        const userRole: Role = this.roles.find(r => r.id == userRoleId);
        if (userRole) this.platformSelectedRoles[userRole.platform] = userRole.id;
      }
    }
  }

  distributorsSearch() {
    this.apiService.getDistributors(this.keyword).subscribe(res => {
      if (res.status) {
        this.distributors = res.data;
      }
    });
  }

  selectDistributor(distributorId) {
    this.selectedDistributorId = "";
    const selectedDistributor: Distributor = this.distributors.find(distributor => distributor.id == distributorId);
    if (selectedDistributor) this.selectedDistributorId = selectedDistributor.id;
    this.distributors = [];
    this.keyword = selectedDistributor.id + " - " +selectedDistributor.description; 
  }

  unselectDistributor() {
    this.distributors = [];
    this.selectedDistributorId = "";
    this.keyword = "";
  }

  doBack(){
    this.router.navigate(['/users']);
  }

  public distributorChange(e): void {
    let distributor = this.distributors.find(x => x?.id === e);
    this.selectedDistributorId = (distributor) ? distributor.id : "";
  }

  doSave(){
    if (!this.user.name){
      this.toastr.error("Name is mandatory");
      return;
    } 
    if (!this.nameRegex.test(this.user.name)){
      this.toastr.error("Invalid name. Can only contain letters");
      return;
    }
    if (!this.user.surname){
      this.toastr.error("Surname is mandatory");
      return;
    }
    if (!this.nameRegex.test(this.user.surname)){
      this.toastr.error("Invalid surname. Can only contain letters");
      return;
    }
    if (!this.user.email){
      this.toastr.error("Email is mandatory");
      return;
    }
    if (!this.emailRegex.test(this.user.email)){
      this.toastr.error("The email must be valid");
      return;
    }
    if (this.user.nationalPhone){
      if (!this.user.countryCode){
        this.toastr.error("The country code is mandatory");
        return;
      }

      if (!this.googleValidationPhone()){
        this.toastr.error("Phone's number format incorrect");
        return;
      }
    }

    this.selectedDistributorId = this.selectedDistributorId === null ? "" : this.selectedDistributorId;
    this.user.distributorId = this.selectedDistributorId;

    if (this.user.countryCode){
      // user.countryCode is prefixCountryCode.dialCode
      // and user.country is prefixCountryCode.countryCode
      this.user.country = this.getCountryCode(this.user.countryCode);
    }

    this.user.professional = this.userType === 'pro';
    
    if (this.user.professional && !this.user.companyName){
      this.toastr.error("You must introduce your company name");
      return;
    }

    this.setUserRoles();

    this.apiService.updateSalicruUser(this.user).subscribe(res => {
      if (res) {
        this.toastr.success("User updated successfully");
      }
    }, error => {
      this.toastr.error("Unable to update user");
    });
  }

  setUserRoles(){
    this.user.roles = [];
    for (const platform of this.platforms){
      if (this.platformSelectedRoles[platform]){
        this.user.roles.push(this.platformSelectedRoles[platform]);
      }
    }
  }

  googleValidationPhone(): boolean {
    const dialCCode = this.user.countryCode.substr(1);
    const PhoneNumber = require('awesome-phonenumber');
    const pn = new PhoneNumber(this.user.nationalPhone, PhoneNumber.getRegionCodeForCountryCode(dialCCode));
    const result = pn.isValid();
    return result;
  }

  getCountryCode(reqDialCode): string {
    let res = this.prefixCountryCode.find(el => el.dialCode === reqDialCode);
    return res.countryCode;
  }

  resetPassword(id: string) {
    this.apiService.resetPassword(id).subscribe(res => {
      this.toastr.success("Password successfully reset");
    }, err => {
      this.toastr.error("Unable to reset password");
    });
  }

}
