<div class="log-form" *ngIf="!isLogged">

  <!-- <div style="background-image: url(assets/images/login_logo.png); height: 50px;"></div> -->
  <div class="row loginHeader">
    <div class="col-1"></div>
    <div class="col-5 slcLoginLogo"></div>
    <div class="col-6">
      <h2>Login</h2>
    </div>
  </div>

  <div *ngIf="!isApiUrlSet">
    <h6 style="padding: 20px 20px;">Select the environment</h6>
    <div id="container-fluid" style="padding: 10px 20px 40px 20px;">
      <div class="row">
        <div class="col-2" style="z-index: 1000;">
          <button (click)="setMode('dev')" class='btn btn-primary float-left'
            style="background: darkgray; border: none;">Development</button>
        </div>
        <div class="col-8 text-center">
        </div>
        <div class="col-2">
          <button (click)="setMode('prod')" class='btn btn-primary float-right'
            style="background: darkgreen; margin-right: 0px; border: none;">Production</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isApiUrlSet">
    <form (ngSubmit)="doLogin()">
      <input type="text" name="email" maxlength="254" autofocus="" required id="email" [(ngModel)]="email"
        placeholder="email">
      <input [type]="passwordType ? 'text' : 'password'" name="password" id="id_password" required
        [(ngModel)]="password" placeholder="Password">
      <span (click)="hideShowPassword($event)" [ngClass]="{
          'fa-eye-slash': passwordType,
          'fa-eye': !passwordType
        }" class="fa fa-fw field-icon toggle-password"></span>
      <button type="submit" class="btn">Login</button>
    </form>
  </div>
</div>

<nav class="navbar fixed-top navbar-expand headerBar" *ngIf="isLogged">
  <div class="headerLogoSpace"></div>
  <div class="headerText">  
    <div class="left-text">
      <span (click)="navToUsers()">Users</span>
      <span (click)="navToRoles()">Roles</span>
    </div>
    <div class="right-text">
      <i class="fa fa-sign-out" style="font-size: 1rem; color: honeydew;"></i><a (click)="logout()"
        href="/"> Logout</a>
    </div>
  </div>
    
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav"
        aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button> -->
</nav>

<div class="navSpace" *ngIf="isLogged"></div>

<router-outlet *ngIf="isLogged"></router-outlet>