import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, AuthService, HttpService} from './services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { UsersComponent } from './pages/users/users.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DoubleConfirmationDialogComponent } from './pages/double-confirmation-dialog/double-confirmation-dialog.component';
import { UserFormComponent } from './pages/user-form/user-form.component';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from  'ngx-ui-loader';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RolesComponent } from './pages/roles/roles.component';
import { RoleFormComponent } from './pages/role-form/role-form.component';


const loaderConfiguration: NgxUiLoaderConfig = {
  "bgsColor": "#333",
  "bgsOpacity": 0.3,
  "bgsPosition": POSITION.centerCenter,
  "bgsSize": 60,
  "bgsType": SPINNER.threeBounce,
  "blur": 7,
  "fgsColor": "#333",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": SPINNER.threeBounce,
  "gap": 24,
  "logoPosition": "center-left",
  "logoSize": 120,
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(218, 218, 218, 0.8)",
  "pbColor": "#00ACC1",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center"
};

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    DoubleConfirmationDialogComponent,
    UserFormComponent,
    DashboardComponent,
    RolesComponent,
    RoleFormComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxUiLoaderModule.forRoot(loaderConfiguration),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [HttpService, ApiService, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
