import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';

@Injectable()
export class ApiService {

  constructor(private httpService: HttpService, private authService: AuthService) { }

  login(email: string, password: string): Observable<any> {
    const url = `${this.authService.getApiUrl()}/api/users/login`;
    const payload = {
      email: email,
      password: password
    };
    return this.httpService.doPost(url, payload, null);
  }

  getUser(id: string): Observable<any> {
    const url = `${this.authService.getApiUrl()}/api/users/${id}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getUsers(limit: number, skip: number, keyword: string): Observable<any> {
    const url = `${this.authService.getApiUrl()}/api/users/getUsers?limit=${limit}&skip=${skip}&keyword=${keyword}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getPage(page: number, size: number): Observable<any> {
    const url = `${this.authService.getApiUrl()}/api/users/getPage?page=${page}&size=${size}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  exportData(): Observable<any>{
    const url = `${this.authService.getApiUrl()}/api/users/export`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  updateSalicruUser(user: User): Observable<any> {
    const url = `${this.authService.getApiUrl()}/api/users/put`;
    return this.httpService.doPost(url, user, this.authService.getToken());
  }
  
  //El poso per costum, pero jo no l'utilitzaria aqui...
  createSalicruUsers(user: User) {
    const url = `${this.authService.getApiUrl()}/api/users`;
    return this.httpService.doPost(url, user, "");
  }

  deleteUser(id: string) {
    const url = `${this.authService.getApiUrl()}/api/users/${id}`;
    return this.httpService.doDelete(url, this.authService.getToken());
  }

  resetPassword(id: string) {
    const url = `${this.authService.getApiUrl()}/api/users/${id}/resetPassword`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getRoles() {
    const url = `${this.authService.getApiUrl()}/api/roles`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getInitialRole(type: string, platform: string) {
    const filter = { where: { type, platform }, limit: 1}
    const url = `${this.authService.getApiUrl()}/api/roles`;
    return this.httpService.doGet(url, this.authService.getToken(), filter);
  }

  getRole(id: string) {
    const url = `${this.authService.getApiUrl()}/api/roles/${id}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getPermissions(platform: string) {
    const url = `${this.authService.getApiUrl()}/api/permissions/${platform}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getTypes(platform: string) {
    const url = `${this.authService.getApiUrl()}/api/roleTypes/${platform}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  getPlatforms() {
    const url = `${this.authService.getApiUrl()}/api/platforms`;
    return this.httpService.doGet(url, this.authService.getToken());
  }

  createRole(role: Role) {
    const url = `${this.authService.getApiUrl()}/api/roles`;
    return this.httpService.doPost(url, role, this.authService.getToken());
  }

  updateRole(roleId: string, role: Role) {
    const url = `${this.authService.getApiUrl()}/api/roles/${roleId}`;
    return this.httpService.doPut(url, role, this.authService.getToken());
  }

  deleteRole(id: string) {
    const url = `${this.authService.getApiUrl()}/api/roles/${id}`;
    return this.httpService.doDelete(url, this.authService.getToken());
  }

  getDistributors(search: string) {
    const url = `${this.authService.getApiUrl()}/api/distributors/search?search=${search}`;
    return this.httpService.doGet(url, this.authService.getToken());
  }
}
