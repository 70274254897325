import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services';
import { Role } from '../../models/role.model';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {

  role: Role = {
    id: "new",
    name: "",
    platform: "",
    permissions: [],
    type: "",
    default: false
  };

  platforms: string[] = [];
  types = [];
  permissions = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.getPlatforms().subscribe(res => {
      if (res.status) {
        this.platforms = res.data;

        this.route.queryParams.subscribe(params => {
          const roleId = params['id'];
          if (roleId) this.loadRole(roleId);
        });
      }
    });
  }

  loadRole(id: string) {
    this.apiService.getRole(id).subscribe(res => {
      if (res['status']) {
        this.role = res.data;
        this.onPlatformChange(true);
      }
    });
  }

  setPermissions() {
    for (const perm of this.role.permissions){
      const checkPerm = this.permissions.find(p => p.desc == perm);
      if (checkPerm) checkPerm.checked = true;
    }
  }

  onPlatformChange(setPermissionsAfter: boolean = false) {
    this.unselectAllPermissions();

    this.apiService.getPermissions(this.role.platform).subscribe(res => {
      if (res['status']) {
        const permissionsStrings: string[] = res.data;
        this.permissions = [];
        for (const permission of permissionsStrings){
          this.permissions.push({desc: permission, checked: false});
        }

        if (setPermissionsAfter) this.setPermissions();

        this.apiService.getTypes(this.role.platform).subscribe((res) => {
          if (res['status']) {
            this.types = res.data;
          }
        }, (error) => {
          console.error(error);
        });
      }
    });
  }

  onTypeChange() {
    this.apiService.getInitialRole(this.role.type, this.role.platform).subscribe((result) => {
      if (result && result.status && result.data && result.data.length) {
        const sampleRole: Role = result.data[0];
        this.unselectAllPermissions();
        for (const initialPermission of sampleRole.permissions) {
          let permissionCheckbox = this.permissions.find((permission) => permission.desc == initialPermission);
          permissionCheckbox.checked = (permissionCheckbox === undefined) ? false : true;
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  doBack() {
    this.router.navigate(['/roles']);
  }

  doSave() {
    if (this.validate()) {
      this.role.permissions = this.permissions.filter(perm => perm.checked).map(perm => perm.desc);
      const newRole = { ...this.role };
      delete newRole.id;
      delete newRole.default;
      delete newRole.initial;
      
      if (this.role.id == "new") {
        this.apiService.createRole(newRole).subscribe(res => {
          if (res.status){
            this.toastr.success("Role created successfully");
            this.router.navigate(['/roles']);
          }
        }, error => {
          this.toastr.error("Unable to create role");
        });
      }
      else {
        this.apiService.updateRole(this.role.id, newRole).subscribe(res => {
          if (res.status){
            this.toastr.success("Role updated successfully");
            this.router.navigate(['/roles']);
          }
        }, error => {
          this.toastr.error("Unable to update role");
        });
      }
    }
  }

  validate(){
    let result: boolean = true;
    this.role.name = this.role.name.trim();
    if (this.role.name == ""){
      result = false;
      this.toastr.error("Role name is mandatory");
    }
    else if (this.role.platform == ""){
      result = false;
      this.toastr.error("Role platform is mandatory");
    }
    else {
      const selectedPermissions = this.permissions.filter((perm) => perm.checked == true);
      if (selectedPermissions.length == 0) {
        result = false;
        this.toastr.error("You must select at least one permission for the role");
      }

      if (this.types && this.types.length && !this.role.type) {
        result = false;
        this.toastr.error("You must select a role type");
      }
    }
    return result;
  }

  selectAllPermissions() {
    for (const p of this.permissions) p.checked = true;
  }

  unselectAllPermissions() {
    for (const p of this.permissions) p.checked = false;
  }

}
