<div class="users-container">
    <div class="row">
        <div class="col-11">
            <h2>Salicru users</h2>
        </div>
        <div class="col-1">
            <button hidden type="button" (click)="export()" class="btn btn-primary" style="float: right;">Export</button>
        </div>
    </div>

    <div class="input-group mt-3">
        <input type="text" class="form-control search" [(ngModel)]="searchParams.keyword" (keydown.enter)="launchSearch()">
        <i *ngIf="searchParams.keyword.length > 0" class="material-icons" id="search-clear" (click)="clearSearch()">close</i>
        <div class="input-group-append">
            <button class="btn btn-secondary search" type="button" (click)="launchSearch()">
                <i class="fa fa-search"></i>
            </button>
        </div>
    </div>

    <div>
        <p style="line-height:4">{{count}} users</p>
    </div>
    
    <div class="row ml-1">
        <div class="col-xs-2">
            <form role="form">
                <div class="form-group">
                <select name="size" class="form-control col-xs-1" [(ngModel)]="searchParams.limit" (change)="onSizeChange()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                    <option value=200>200</option>
                </select>
                </div>
            </form>
        </div>

        <div class="col-xs-5 mx-auto">
        <ngb-pagination [collectionSize]="count" [pageSize]="searchParams.limit" [(page)]="page" [maxSize]="5"
            [boundaryLinks]="true" (pageChange)="onPageChange()"></ngb-pagination>
        </div>
    </div>
    

    <table class="table" aria-describedby="mydesc">
        <thead>
            <tr style="text-align: left;">
                <th *ngFor="let column of columns" scope="col">
                    <span *ngIf="!column.sortable">
                        {{ column.name }}
                    </span>
                    <span *ngIf="column.sortable" (click)="toggleSortable(column)">
                        {{ column.name }}
                        <i class="fa fa-xs fa-chevron-up sort-icon"
                            *ngIf="column.field == searchParams.sortColumn && searchParams.sortAsc">
                        </i>
                        <i class="fa fa-xs fa-chevron-down sort-icon"
                            *ngIf="column.field == searchParams.sortColumn && !searchParams.sortAsc">
                        </i>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{ user.name }}</td>
                <td>{{ user.surname }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.createdAtStr }}</td>
                <td>
                    <i *ngIf="user.commercialOk" class="fa fa-check" style="color:green"></i>
                    <i *ngIf="!user.commercialOk" class="fa fa-times" style="color:#BB3726"></i>
                </td>
                <td>
                    <span *ngFor="let platform of user.platforms">
                        <p style="margin-bottom:0">{{ platform }}</p>
                    </span>
                </td>
                <td>
                    <span *ngIf="user.professional">{{ user.companyName }}</span>
                    <span *ngIf="!user.professional">Particular</span>
                </td>
                <td><span *ngIf="user.country">{{ user.country }}</span></td>
                <td>
                    <i *ngIf="user.accountVerified" class="fa fa-check" style="color:green"></i>
                    <i *ngIf="!user.accountVerified" class="fa fa-times" style="color:#BB3726"></i>
                </td>
                <td class="text-right">
                    <button *ngIf="!user.accountVerified" class="btn btn-primary m-1" (click)="verifyUser(user)"
                        placement="bottom" ngbTooltip="Verify user">
                        <i class="fa fa-fw fa-check"></i>
                    </button>
                    <button class="btn btn-secondary m-1" (click)="editUser(user)" placement="bottom" ngbTooltip="Edit user">
                        <i class="fa fa-fw fa-edit"></i>
                    </button>
                    <button class="btn btn-danger m-1" (click)="deleteUser(user)" placement="bottom" ngbTooltip="Delete user">
                        <i class="fa fa-fw fa-trash"></i>
                    </button> 
                </td>
            </tr>
        </tbody>
    </table>
</div>