import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/models/role.model';
import { ApiService } from 'src/app/services';
import { DoubleConfirmationDialogService } from 'src/app/services/double-confirmation-dialog.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  roles: Role[] = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private doubleConfirmationDialogService: DoubleConfirmationDialogService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadRoles();
  }

  loadRoles() {
    this.apiService.getRoles().subscribe((res) => {
      if (res['status']) {
        this.roles = res.data;
      }
    });
  }

  createRole() {
    this.router.navigate(['/roles/create']);
  }

  editRole(role) {
    this.router.navigate(['/roles/edit'], { queryParams: { id: role.id }});
  }

  deleteRole(role) {
    this.doubleConfirmationDialogService
      .confirm('Delete role', 
        'Do you really want to delete the role?', 
        'role\'s name',
        role.name)
      .then((confirmed) => {
        if (confirmed)
          this.apiService.deleteRole(role.id).subscribe((res) => {
            this.toastr.success("Role deleted successfully");
            this.loadRoles();
          }, error => {
            if (error) {
              this.toastr.error(error);
            } else {
              this.toastr.error("Unable to delete role");
            }
          });
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
