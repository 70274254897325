import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService, ApiService } from 'src/app/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  email: string = "";
  password: string = "";
  message: string;
  error: string;
  isCollapsed = true;
  passwordType: boolean = false;
  isLogged = false;
  isApiUrlSet: boolean = false;
  title = 'users-admin-panel';

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.isLogged = this.authService.isLogged();
    this.isApiUrlSet = this.authService.getApiUrl() != null;
    if (!this.isLogged) {
      this.router.navigate(['/']);
    }
  }

  doLogin() {
    this.error = null;
    this.apiService.login(this.email, this.password).subscribe(response => {
      if (response && response.data && response.data.token) {
        this.authService.putToken(response.data.token);
        this.isLogged = true;
        this.router.navigate(['/users']);
      } else {
        this.toastr.error("Wrong username or password. Please try it again.")
      }
    }, error => {
      this.toastr.error("Wrong username or password. Please try it again.")
    })
  }

  isActive(path: string) {
    return this.router.url === path;
  }

  logout() {
    this.authService.clearToken()
    this.authService.clearApiUrl();
    this.router.navigate(['/']);
  }

  backToMode() {
    this.authService.clearApiUrl();
    location.reload()
  }

  hideShowPassword(event) {
    this.passwordType = !this.passwordType;
  }

  navToUsers() {
    this.router.navigate(['/users']);
  }

  navToRoles() {
    this.router.navigate(['/roles']);
  }

}
