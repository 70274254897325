import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './pages/users/users.component';
import { UserFormComponent } from './pages/user-form/user-form.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RolesComponent } from './pages/roles/roles.component';
import { RoleFormComponent } from './pages/role-form/role-form.component';
// import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
  { path: '', component: DashboardComponent,
  children: [
    { path: '', redirectTo: 'users', pathMatch: 'full' },
    { path: 'users/edit', component: UserFormComponent },
    { path: 'users', component: UsersComponent, /*canActivate: [AuthGuardService]*/ },
    { path: 'roles', component: RolesComponent },
    { path: 'roles/create', component: RoleFormComponent },
    { path: 'roles/edit', component: RoleFormComponent }
  ]},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
