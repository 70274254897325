<div class="form-container pb-5" *ngIf="user">
  
  <h2>Edit User</h2>
  
  <form role="form">
    <div class="col-sm-12 wideForm">
      <div class="card mb-3">
        <div class="card-header">User info</div>
        <div class="card-body row">

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>User id</label>
              <input name="id" placeholder="User id" disabled
                [(ngModel)]="user.id" class="form-control">
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Created at</label>
              <input name="createdAt" placeholder="Created at" disabled
                [(ngModel)]="user.createdAt" class="form-control">
            </fieldset>
          
            <!-- <span class="col-lg-6 d-none d-lg-block"></span> -->
          
            <fieldset class="form-group col-sm-12 col-lg-6">
              <label class="required">Name</label>
              <input name="name" placeholder="Name" 
                [(ngModel)]="user.name" class="form-control">
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label class="required">Surname</label>
              <input name="surname" placeholder="Surname" 
                [(ngModel)]="user.surname" class="form-control">
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label class="required">Email</label>
              <input name="email" placeholder="Email"
                [(ngModel)]="user.email" class="form-control">
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Phone</label>
              <div class="selectField">
                  <select class="btn-group form-control" role="group" name="countryCode"
                      [(ngModel)]="user.countryCode">
                      <option value="{{country.dialCode}}" *ngFor="let country of prefixCountryCode">
                          {{ country.name }} ({{country.dialCode}})</option>
                  </select>
                  <input class="btn-group form-control" role="group" name="nationalPhone" type="text"
                      placeholder="912 345 678" [(ngModel)]="user.nationalPhone" class="form-control">
              </div>
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Account type</label>
              <select class="btn-group form-control" role="group" name="userType"
                      [(ngModel)]="userType">
                <option value="{{type.value}}" *ngFor="let type of userTypes">
                    {{ type.display }}</option>
              </select>
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Company name</label>
              <input name="companyName" placeholder="Company name"
                [(ngModel)]="user.companyName" class="form-control"
                disabled="{{userType!=='pro'}}">
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6 p-0">
              <fieldset class="form-group col-lg-12">
                <input name="ads" type="checkbox"
                  [(ngModel)]="user.commercialOk">
                Receive commercial information
              </fieldset>
  
              <fieldset class="form-group col-lg-12">
                <label>Active at following platforms:</label>
                <p *ngIf="user.platforms">{{user.platforms.join(', ')}}</p>
                <p *ngIf="!user.platforms || user.platforms.length===0">none</p>
              </fieldset>
  
              <fieldset class="form-group col-sm-12">
                <button class="btn btn-primary" type="button" 
                (click)="resetPassword(user.id)">Reset Password</button>
              </fieldset>
            </fieldset>

            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Platform roles</label>
              <div class="platformRoles" *ngFor="let platform of platforms">
                <label>{{platform}}:</label>
                <select class="btn-group form-control" role="group" name="{{platform}}-role"
                        [(ngModel)]="platformSelectedRoles[platform]">
                  <option value="{{role.id}}" *ngFor="let role of platformRoles[platform]">
                      {{role.name}}</option>
                </select>
              </div>
            </fieldset>
            <fieldset class="form-group col-sm-12 col-lg-6"></fieldset>
            <fieldset class="form-group col-sm-12 col-lg-6">
              <label>Distributor:</label>
              <div class="distributorSelector">
                <div class="input-group mt-3">
                  <input type="text" class="form-control search" name="distributor" id="distributorSelector"
                         [disabled]="selectedDistributorId != ''" [(ngModel)]="keyword"
                         (keydown.enter)="distributorsSearch()">
                  <div class="input-group-append">
                      <button class="btn btn-secondary search" type="button" *ngIf="!selectedDistributorId"
                              (click)="distributorsSearch()">
                          <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-secondary" type="button" *ngIf="selectedDistributorId"
                              (click)="unselectDistributor()">
                        <i class="fa fa-close"></i>
                    </button>
                  </div>
                </div>
                <div id="searchResults">
                  <div class="distributorResult" *ngFor="let distributor of distributors"
                       (click)="selectDistributor(distributor.id)">
                    <strong>{{ distributor.id }}</strong><span> - {{ distributor.description }}</span>
                  </div>
                </div>
              </div>
            </fieldset>
        </div>
      </div>
    </div>
  </form>
  <button class="btn btn-danger" type="button" (click)="doBack()">Back</button>
  <button class="btn btn-success pull-right" type="button" (click)="doSave()">Save</button>

</div>
