<div class="form-container pb-5">

  <h2 *ngIf="role.id == 'new'">New role</h2>
  <h2 *ngIf="role.id != 'new'">Edit role</h2>

  <form role="form">
    <div class="col-sm-12 wideForm">
      <div class="card my-3">
        <div class="card-header">Role info</div>
        <div class="card-body row">

          <fieldset class="form-group col-sm-12 col-lg-6">
            <label class="required">Name</label>
            <input name="name" placeholder="Name" [(ngModel)]="role.name" class="form-control" [disabled]="role.id != 'new' && role.initial">
          </fieldset>

          <fieldset class="form-group col-sm-12 col-lg-6">
            <label class="required">Platform</label>
            <select class="btn-group form-control" role="group" name="platform" [disabled]="role.id != 'new'"
              [(ngModel)]="role.platform" (change)="onPlatformChange()">
              <option value="{{platformName}}" *ngFor="let platformName of platforms">
                {{ platformName }}</option>
            </select>
          </fieldset>

          <fieldset class="form-group col-sm-12 col-lg-6" *ngIf="types && types.length">
            <label class="required">Types</label>
            <select class="btn-group form-control" role="group" name="type" [disabled]="role.id != 'new'"
              [(ngModel)]="role.type" (change)="onTypeChange()">
              <option value="{{type}}" *ngFor="let type of types">
                {{ type }}</option>
            </select>
          </fieldset>
          <fieldset class="form-group col-sm-12 col-lg-6" *ngIf="types && types.length"></fieldset>

          <fieldset class="form-group col-sm-12 col-lg-6">
            <label class="required">Permissions</label>
            <a class="float-right ml-2" (click)="selectAllPermissions()">Select all</a>
            <a class="float-right" (click)="unselectAllPermissions()">Unselect all</a>
            <div class="card permissions-container">
              <span *ngIf="role.platform == ''" class="mt-15">Select a platform to see its permissions</span>
              <div *ngFor="let permission of permissions">
                <input id="check-{{permission.desc}}" name="perm-{{permission.desc}}" type="checkbox" [(ngModel)]="permission.checked">
                <label for="check-{{permission.desc}}" class="checkbox-label">{{permission.desc}}</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

  </form>
  <button class="btn btn-danger" type="button" (click)="doBack()">Back</button>
  <button class="btn btn-success pull-right" type="button" (click)="doSave()" *ngIf="role.id == 'new'">Create</button>
  <button class="btn btn-success pull-right" type="button" (click)="doSave()" *ngIf="role.id != 'new'">Save</button>

</div>